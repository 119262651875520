/* Variables */

:root {

    --body-color: #fff;
    --main-content-backgroundColor: #0e1014;
    --modal-full-backgroundColor: #0e1014;
    --footer-backgroundColor: #0e1014;
    --footer-borderTop-Color: rgba(228, 228, 228, 0.1);
    --footer-color: #808191;
    --h1-color: #fff;
    --h2-color: #C8FF00;
    --h3-color: #808191;
    --h4-color: #fff;
    --h5-color: #fff;
    --a-color: #C8FF00;
    --a-color-hover: #addd00;
    --highlighted-color: #C8FF00;
    --text-muted-color: #808191;
    --image-blank-backgroundColor: #191B20;
    --instructions-color: #56618F;

    --binary-images-with-upload--add-backgroundColor: #191B20;
    --binary-images-with-upload--add-backgroundColor-alt: #191B20;
    --binary-videos-with-upload--add-backgroundColor: #191B20;
    --binary-videos-with-upload--add-backgroundColor-alt: #191B20;
    --divider-color: rgba(228, 228, 228, 0.1);
    --form-section--backgroundColor: #242731;
    --form-section--backgroundColor-alt: #000;
    --form-section--h5-color: #2A3554;
    --lead-color: #808191;
    --alert-default-backgroundColor: #191B20;
    --alert-default-borderColor: #191B20;
    --alert-default-color: #fff;
    --alert-default--icon-backgroundColor: #000;
    --alert-default--icon-color: #fff;
    --alert-success-backgroundColor: #EAF6EA;
    --alert-success-borderColor: #EAF6EA;
    --alert-success-color: #2A3554;
    --alert-success--icon-backgroundColor: #5cb85c;
    --alert-success--icon-color: #fff;
    --alert-danger-backgroundColor: #FF3E55;
    --alert-danger-borderColor: #FF3E55;
    --alert-danger-color: #fff;
    --alert-danger--icon-backgroundColor: #f00;
    --alert-danger--icon-color: #fff;
    --alert-warning-backgroundColor: #FFFAED;
    --alert-warning-borderColor: #FFFAED;
    --alert-warning-color: #2A3554;
    --alert-warning--icon-backgroundColor: #FFC107;
    --alert-warning--icon-color: #fff;
    --btn-backgroundColor--disabled: #000;
    --btn-color--disabled: #fff;
    --btn-primary-color: #000;
    --btn-primary-backgroundColor: #C8FF00;
    --btn-primary-backgroundColor-focus: #addd00;
    --btn-default-color: #fff;
    --btn-default-backgroundColor: #242731;
    --btn-default-backgroundColor-focus: #1F2128;
    --form-control-color: #fff;
    --form-control-backgroundColor: #191B20;
    --form-control-borderColor: #191B20;
    --form-control-borderColor--focus: #C8FF00;
    --form-control-backgroundColor--focus: #1F2128;
    --form-control-color--has-warning: #fff;
    --form-control-backgroundColor--has-warning: #191B20;
    --form-control-borderColor--has-warning: #191B20;
    --form-control-borderColor--has-error: #FF3E55;
    --form-control-color--disabled: #fff;
    --form-control-backgroundColor--disabled: #242731;
    --form-control-label-color: #808191;
    --form-control-label-color--has-warning: #808191;
    --form-control-help-block-color: #5f606e;
    --form-group-required--form-control-label-color: #C8FF00;
    --modal-dialog-backgroundColor: #2b2f3b;
    --modal-dialog-header-backgroundColor: #2b2f3b;
    --modal-dialog-header-borderBottomColor: #000;
    --network-bar-backgroundColor: #C8FF00;
    --pagination--a-backgroundColor: transparent;
    --pagination--a-backgroundColor-hover: #C8FF00;
    --pagination--a-borderColor-hover: rgba(228, 228, 228, 0.1);
    --pagination--a-borderColor: rgba(228, 228, 228, 0.1);
    --pagination--a-color: #f2f1ef;
    --pagination--disabled--a-color: #f2f1ef;
    --pagination--disabled--a-backgroundColor: transparent;
    --pagination--disabled--a-borderColor: rgba(228, 228, 228, 0.1);
    --pagination--active--a-backgroundColor: #C8FF00;
    --pagination--active--a-borderColor: rgba(228, 228, 228, 0.1);
    --pagination--active--a-color: #000;
    --panel-default-backgroundColor: #242731;
    --panel-default-borderColor: rgba(228, 228, 228, 0.1);
    --panel-dialog-backgroundColor: #242731;
    --panel-dialog-borderColor: rgba(228, 228, 228, 0.1);
    --panel-dialog--a-backgroundColor: #242731;
    --panel-table-responsive-backgroundColor: 'transparent';
    --panel-table-borderColor: transparent;
    --panel-table--th-backgroundColor: #242731;
    --panel-table--th-backgroundColor-alt: #1F2128;
    --panel-table--th-borderColor: transparent;
    --panel-table--th-color: #808191;
    --panel-table-tr-backgroundColor: #1d1f27;
    --panel-table-tr-backgroundColor-alt: #242731;
    --panel-table-tr-backgroundColor-hover: #0e1014;
    --panel-table-tr-backgroundColor-blank: #000;
    --panel-table--tr-borderColor: transparent;
    --primary-nav-backgroundColor: #0e1014;
    --primary-nav-borderBottom-color: rgba(228, 228, 228, 0.1);
    --primary-nav--a-color: #808191;
    --primary-nav--navbar-brand--a-color: #fff;
    --primary-nav--navbar-toggle--span-backgroundColor: #fff;
    --primary-nav--section-color: #fff;
    --secondary-nav-backgroundColor: #0e1014;
    --secondary-nav-borderColor: rgba(228, 228, 228, 0.1);
    --secondary-nav-color: #808191;
    --secondary-nav-color-active: #C8FF00;
    --secondary-nav--item-borderColor: #0e1014;
    --secondary-nav--item-borderColor-active: #C8FF00;
    --list-blocks--drag-color: #C8FF00;
    --tab-color: #fff;
    --tab-backgroundColor: #242731;
    --tab-backgroundColor-active: #C8FF00;
    --tab-borderColor: rgba(228, 228, 228, 0.1);
    --tab-borderColor-active: rgba(228, 228, 228, 0.1);
    --tab-color-active: #000;
    --tab-backgroundColor-hover: #1F2128;
    --tab-borderColor-hover: rgba(228, 228, 228, 0.1);
    --tab-color-hover: #fff;
    --dashboard-layout--secondary-nav-h4-color: #fff;
    --dashboard-layout--secondary-nav-backgroundColor: #141414;
    --dashboard-layout--secondary-nav-active-backgroundColor: #FAF8F9;

}

/* Container */

body, html {
    overflow-x: auto;
}

body {
    background: #fff;
    color: var(--body-color);
    font-family: "Inter", arial, helvetica, sans-serif;
    font-weight: 300;
    font-size: 13px;
}

body:after {
    background: var(--footer-backgroundColor);
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

#page {
    background: var(--footer-backgroundColor);
}

html, body, #root, #root > div, #page {
}

pre {
    background: #fff;
    border: 1px solid #F2F1EF;
    font-size: 14px;
    font-weight: 300;
    font-family: monospace;
}

.actions {
    margin: 60px 0 140px 0;
}

.actions > * {
    vertical-align: baseline;
}

.form-inline > * {
    vertical-align: baseline !important;
}

.shadowed-normal {
    -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

/* Panel */

.panel-default {
    background: var(--panel-default-backgroundColor);
    border-color: var(--panel-default-borderColor);
    border-radius: 4px;
}

.panel-default .panel-body > :first-child {
}

.panel-default .panel-body > :last-child {
    margin-bottom: 0 !important;
}

.panel-default + h3 {
    margin-top: 40px;
}

/* Divider */

.panel-body div.divider {
    border-color: var(--divider-color);
}

.table-wrapper + .divider {
    margin-top: 40px !important;
}

/* Headings */

.text-center > h1,
.text-center > h2,
.text-center > h3,
.text-center > h4,
.text-center > h5,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center {
    text-align: center;
}

h1 {
    color: var(--h1-color);
    font-size: 35px;
    font-weight: 600;
    line-height: 1.3em;
}

h1.heading {
}

h1 + .panel {
    margin-top: 40px;
}

div.heading, h1.heading {
    margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
    .text-left .h1, .text-left h1 {
        text-align: left;
    }
}

h2 {
    color: var(--h2-color);
    font-size: 18px;
    font-weight: 500;
}

h2.meta {
    font-size: 18px;
    margin-top: -10px;
    margin-bottom: 40px;
}

.panel + h2 {
    margin-top: 40px;
}

h3 {
    color: var(--h3-color);
    font-size: 16px;
    font-weight: 400;
}

h3 .btn {
    font-size: 13px;
    padding: 6px 20px;
}

h4 {
    color: var(--h4-color);
    font-size: 14px;
    font-weight: 400;
}

h5 {
    color: var(--h5-color);
    font-size: 12px;
    font-weight: 400;
}

.divider + h1,
.divider + h2,
.divider + h3,
.divider + h4,
.divider + h5,
.divider + h6 {
    margin-top: 0;
}

.panel-body .form-group + .divider {
    margin-top: 40px;
}

@media screen and (max-width: 1200px) {
    h1 > span.pull-right,
    h2 > span.pull-right,
    h3 > span.pull-right,
    h4 > span.pull-right,
    h5 > span.pull-right,
    h6 > span.pull-right {
        display: block;
        float: none !important;
        margin-top: 20px;
    }
}

/* Common */

a {
    color: var(--a-color);
}

a:hover, a:active, a:focus {
    color: var(--a-color-hover);
}

.highlighted {
    color: var(--highlighted-color);
}

.lead {
    color: var(--lead-color);
    max-width: 800px;
    font-size: 18px;
}

.lead .back {
    white-space: nowrap;
}

.text-secondary {
    color: hsl(0, 0%, 80%) !important;
}

.text-secondary.clickable:hover {
    color: hsl(0, 0%, 50%) !important;
}

.text-muted {
    color: var(--text-muted-color);
}

.text-blank {
    color: #ccc;
}

.fa-times {
    -webkit-text-stroke: 1px white;
}

/* Main Content */

#main-content {
    background: var(--main-content-backgroundColor);
    margin-top: 51px;
    min-height: 435px;
}

@media screen and (max-width: 1200px) {
    #main-content {
        margin-top: 0;
    }
}

#main-content.has-secondary-nav {
    padding-top: 110px;
}

@media screen and (max-width: 1200px) {
    #main-content.has-secondary-nav {
        padding-top: 61px;
    }
}

#main-content > .container-full {
    margin-top: 0;
    margin-bottom: -60px;
}

#main-content .row:last-child form:last-child .actions:last-child {
    margin-bottom: 40px;
}

#main-content .row:last-child form:last-child .actions:last-child:after {
    content: "";
    display: block;
    clear: both;
}

/* Network Progress Bar */

.network-bar-container .network-bar {
    background: var(--network-bar-backgroundColor);
}

/* Primary Nav */

#primary-nav {
    background: var(--primary-nav-backgroundColor);
    border-top: 0;
    border-bottom-color: var(--primary-nav-borderBottom-color);
}

#primary-nav #app-title a {
    font-weight: 400;
}

#primary-nav .nav a,
#primary-nav a {
    color: var(--primary-nav--a-color);
}

#primary-nav .nav a {
    font-size: 12px;
}

#primary-nav .nav li button {
    margin-top: 10px;
    margin-left: 10px;
}

#primary-nav .navbar-collapse.collapsing,
#primary-nav .navbar-collapse.in {
    background: var(--primary-nav-backgroundColor) !important;
    border-color: var(--primary-nav-borderBottom-color) !important;
}

#primary-nav .navbar-collapse li {
    text-align: center;
}

#primary-nav .navbar-collapse li.open > a {
    background: #000;
}

#primary-nav .navbar-brand a {
    color: var(--primary-nav--navbar-brand--a-color);
}

#primary-nav .navbar-brand .logo {
    max-height: 20px;
    margin-top: -8px;
    margin-right: 10px;
}

#primary-nav .navbar-toggle {
    background: transparent;
    border: 0;
}

#primary-nav .navbar-toggle span {
    background-color: var(--primary-nav--navbar-toggle--span-backgroundColor);
}

#primary-nav .section {
    color: var(--primary-nav--section-color);
}

@media screen and (max-width: 1200px) {

    #primary-nav #app-title {
        display: block;
        overflow: hidden;
        margin-left: 50px;
        margin-right: 50px;
        text-align: center;
    }

    #primary-nav #app-title > a{
        text-align: center;
    }

    #primary-nav .navbar-collapse li button {
        margin-bottom: 20px;
        margin-left: 0;
    }

}

@media screen and (min-width: 1201px) {
    #primary-nav {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 200;
    }
}

/* Secondary Nav */

#secondary-nav {
    background: var(--secondary-nav-backgroundColor);
    border-color: var(--secondary-nav-borderColor);
    margin-bottom: 0;
}

#secondary-nav .nav {
    width: 100%;
    color: var(--secondary-nav-color);
}

#secondary-nav .nav > li > a,
#secondary-nav .nav > li > a:hover {
    border-bottom-color: var(--secondary-nav--item-borderColor);
    color: var(--secondary-nav-color);
    text-shadow: none;
}

#secondary-nav .nav > li > a.active,
#secondary-nav .nav > li > a.active:hover {
    border-bottom-color: var(--secondary-nav--item-borderColor-active);
    color: var(--secondary-nav-color-active);
}

#secondary-nav .nav > li.pull-right > a {
    margin: 0 0 0 30px;
}

#secondary-nav .nav button {
    margin: -10px 0;
}

#secondary-nav .nav li a .fa {
    display: none;
    margin-right: 10px;
}

#secondary-nav .nav li.dashboard-title {
    display: none;
}

#secondary-nav .nav > li > .btn-primary {
    color: var(--btn-primary-color);
    padding: 5px 10px;
    border: 0;
    border-radius: 4px;
    margin-top: 9px !important;
}

#secondary-nav .nav > li > .btn-primary:hover,
#secondary-nav .nav > li > .btn-primary:active,
#secondary-nav .nav > li > .btn-primary:active {
    background: var(--btn-primary-backgroundColor-focus);
    color: var(--btn-primary-color);
}

@media screen and (max-width: 1200px) {

    #secondary-nav {
        padding-left: 0;
        padding-right: 0;
    }

    #secondary-nav > .container {
        padding-left: 0;
        padding-right: 0;
    }

    #secondary-nav > .container > .row {
        margin-left: 0;
        margin-right: 0;
    }

    #secondary-nav .navbar-nav {
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    #secondary-nav .navbar-nav::-webkit-scrollbar {
        display: none;
    }

    #secondary-nav .navbar-nav > li {
        display: inline-block;
        float: none;
    }

}

@media screen and (max-width: 1200px) {

    #secondary-nav .nav > li.pull-right {
        float: none !important;
    }

    #secondary-nav .nav > li.pull-right > a {
        margin: 0;
    }

    #secondary-nav .nav button {
        margin: 0 0 5px 0;
    }

}

@media screen and (min-width: 1201px) {
    #secondary-nav {
        position: fixed;
        top: 51px;
        width: 100%;
        z-index: 199;
    }
}

/* Global Message */

.alert {
    background: var(--alert-default-backgroundColor);
    border: 1px solid var(--alert-default-borderColor);
    color: var(--alert-default-color);
    padding: 15px 15px 15px 70px;
    /*padding: 15px 15px 15px 15px;*/
    position: relative;
    text-align: left;
    box-shadow: none;
    border-radius: 4px;
}

.alert:before {
    background: var(--alert-default--icon-backgroundColor);
    color: var(--alert-default--icon-color);
    content: "\f071";
    font-family: "FontAwesome", serif;
    margin-right: 15px;
    padding: 17px 20px;
    top: -1px;
    left: -1px;
    bottom: -1px;
    position: absolute;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.alert.alert-success {
    background: var(--alert-success-backgroundColor);
    border-color: var(--alert-success-borderColor);
    color: var(--alert-success-color);
}

.alert.alert-success:before {
    background: var(--alert-success--icon-backgroundColor);
    color: var(--alert-success--icon-color);
    content: "\f00C";
}

.alert.alert-danger {
    background: var(--alert-danger-backgroundColor);
    border-color: var(--alert-danger-borderColor);
    color: var(--alert-danger-color);
}

.alert.alert-danger:before {
    background: var(--alert-danger--icon-backgroundColor);
    color: var(--alert-danger--icon-color);
}

.alert.alert-warning {
    background: var(--alert-warning-backgroundColor);
    border-color: var(--alert-warning-borderColor);
    color: var(--alert-warning-color);
}

.alert.alert-warning:before {
    background: var(--alert-warning--icon-backgroundColor);
    color: var(--alert-warning--icon-color);
}

/* Panel */


/* Modal Full */

.modal.full .modal-dialog {
    transition: 0.25s ease-in-out;
}

.modal.full.fade .modal-dialog {
    top: 25%;
}

.modal.full.fade.in .modal-dialog {
    height: calc(100% + 51px);
    overflow: scroll;
}

.modal.full.fade.in.stable .modal-dialog {
    top: 0;
}

@media screen and (max-width: 1200px) {
    .modal.full .modal-header > .container h1 {
        margin-top: 0;
    }
}

.modal.full .lead {
    margin-top: -15px;
}

.modal.full h1 + .lead {
    margin-top: 0;
}

.modal.full.centered-title h1,
.modal.full.centered-title h2,
.modal.full.centered-title h3,
.modal.full.centered-title h4,
.modal.full.centered-title h5,
.modal.full.centered-title h6,
.modal.full.centered-title .lead {
    text-align: center;
}

.modal.full .modal-body {
    padding-top: 10px;
}

.modal.full .modal-content {
    background: var(--modal-full-backgroundColor);
}

.modal.full .modal-header button.close {
    background: var(--text-muted-color);
    font-size: 24px;
    opacity: 1.0;
    margin: 0;
    text-shadow: none;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.modal.full .modal-header button.close:hover {
    background: #fff;
}

/* Footer */

#footer-container {
    background: var(--footer-backgroundColor);
    border-top-color: var(--footer-borderTop-Color);
    color: var(--footer-color);
    padding-top: 40px;
}

#footer-container a {
    color: var(--footer-color);
}

#footer-container a:hover {
    color: #fff;
}

#footer .title {
    color: #fff;
    margin-top: 30px;
}

#footer .tagline {
    color: #fff;
}

#footer-links {
    border-top-color: var(--footer-borderTop-Color);
}

#footer-content ul {
    margin: 0;
    padding: 0;
}

#footer-content .list-title {
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 30px;
}

#footer-content ul li {
    list-style: none;
    margin: 5px 0;
    padding: 0;
}

#footer-nav {
    float: unset;
    position: unset;
    left: unset;
}

/* Dialog Panel including Account Dialog Panel */

.dialog-panel {
}

.dialog-panel .tabbed-pills-container > ul > li > a {
    background: var(--panel-dialog--a-backgroundColor);
    color: var(--panel-dialog-borderColor);
}

.dialog-panel .tabbed-pills-container > ul > li > a:hover {
    color: var(--btn-primary-color);
}

.dialog-panel .tabbed-pills-container > ul > li.active > a {
    background: var(--btn-primary-backgroundColor);
    color: var(--btn-primary-color);
}

.dialog-panel .tabbed-pills-container > ul > li:first-child > a {
    /*-moz-border-radius-topleft: 24px;*/
    /*-webkit-border-top-left-radius: 24px;*/
    /*border-top-left-radius: 24px;*/
}

.dialog-panel .tabbed-pills-container > ul > li:last-child > a {
    /*-moz-border-radius-topright: 24px;*/
    /*-webkit-border-top-right-radius: 24px;*/
    /*border-top-right-radius: 24px;*/
}

.dialog-panel .nav-justified {
    width: 99.95%;
}

.dialog-panel .help-block.error {
    display: block;
}

@media (max-width: 767px) {

    .dialog-panel .tabbed-pills-container {
        text-align: center;
        clear: both;
        margin: -20px 0 30px 0;
    }

    .dialog-panel .nav-justified {
        display: inline;
        margin: 0 auto;
        overflow: hidden;
        width: auto;
    }

    .dialog-panel .nav-justified > li {
        display: table-cell;
    }

    .dialog-panel .nav-justified > li > a {
        width: 85px;
        -moz-border-radius: 12px !important;
        -webkit-border-radius: 12px !important;
        border-radius: 12px !important;
    }

    .dialog-panel .nav-justified > li > a > .fa {
        font-size: 18px;
    }

}

@media (max-width: 415px) {

    .dialog-panel .nav-justified > li > a {
        width: 55px;
    }

    .dialog-panel .nav-justified > li > a .fa {
        margin: 0;
    }

    .dialog-panel .nav-justified > li > a .lbl {
        display: none;
    }

}

/* Confirm - modal dialog */

.modal-header {
    background-color: var(--modal-dialog-header-backgroundColor);
    border-bottom-color: var(--modal-dialog-header-borderBottomColor);
}

.modal-body,
.modal-content {
    background-color: var(--modal-dialog-backgroundColor);
}

/* Table */

.table-responsive {
    background-color: var(--panel-table-responsive-backgroundColor);
    border-color: var(--panel-table-borderColor);
    padding: 0;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.table tr > th:first-child {
    -moz-border-radius-topleft: 4px !important;
    -moz-border-radius-bottomleft: 0 !important;
    -webkit-border-top-left-radius: 4px !important;
    -webkit-border-bottom-left-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
}

.table tr > th:last-child {
    -moz-border-radius-topright: 4px !important;
    -moz-border-radius-bottomright: 0 !important;
    -webkit-border-top-right-radius: 4px !important;
    -webkit-border-bottom-right-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 0 !important;
}

.table > thead > tr > th {
    background: var(--panel-table--th-backgroundColor);
    border-bottom-color: var(--panel-table--th-borderColor);
    color: var(--panel-table--th-color);
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
}

.panel-default .table > thead > tr > th {
    background: var(--panel-table--th-backgroundColor-alt);
    border-bottom-color: var(--panel-table--th-borderColor);
}

.table > tbody > tr > td {
    padding-top: 20px;
    padding-bottom: 20px;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.table > tbody > tr:nth-child(odd) > td {
    background: var(--panel-table-tr-backgroundColor);
}

.table > tbody > tr:nth-child(even) > td {
    background: var(--panel-table-tr-backgroundColor-alt);
}

.table > tbody > tr:hover > td {
    background: var(--panel-table-tr-backgroundColor-hover);
}

.table > tbody > tr > td.blank {
    background: var(--panel-table-tr-backgroundColor-blank);
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top-color: var(--panel-table--tr-borderColor);
}

.table strong {
    font-weight: 500;
}

.table .btn-group-xs {
    margin-top: -3px;
}

.table th.col-chevron {
    min-width: 61px;
}

.table .fa.fa-chevron-right {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 767px) {
    .panel .table-responsive {
        overflow: auto;
    }
}

.panel-body .table-wrapper:last-child .panel-table {
    margin-bottom: 0;
}

/* Pagination */

.pagination > li > a {
    background-color: var(--pagination--a-backgroundColor);
    border-color: var(--pagination--a-borderColor);
    color: var(--pagination--a-color);
}

.pagination > li:hover > a {
    background-color: var(--pagination--a-backgroundColor-hover);
    border-color: var(--pagination--a-borderColor-hover);
    color: var(--pagination--active--a-color);
}

.pagination > li.disabled > a {
    color: var(--pagination--disabled--a-color);
    background-color: var(--pagination--disabled--a-backgroundColor);
    border-color: var(--pagination--disabled--a-borderColor);
}

.pagination li.active a,
.pagination li.active a:hover,
.pagination li.active a:focus,
.pagination li.active a:active {
    background: var(--pagination--active--a-backgroundColor);
    border-color: var(--pagination--active--a-borderColor);
    color: var(--pagination--active--a-color);
}

/* Name Values */

.name-values {
    border: 0;
}

.panel-body .name-values.has-heading {
    margin: -35px 0 -12px 0 !important;
}

.name-values .name-value {
    border: 0;
}

.name-values .name-value > * {
    border: 0;
}

.name-values .name-value > .name {
    background: transparent;
    border: 0;
    color: #56618F;
    font-size: 12px;
    font-weight: 500;
    padding-top: 12px;
    text-transform: uppercase;
}

.name-values .name-value > .value {
    background: transparent;
}

.name-values .name-value > * {
    padding: 10px 0;
}

.name-values .name-value.heading {
    background: transparent;
    border-bottom: 1px solid #f2f1ef;
    padding-bottom: 5px;
    margin-bottom: 25px;
}

.panel-body .name-values .name-value.heading {
    background: #FAFBFC;
    margin-left: -35px;
    margin-right: -35px;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.panel-body .name-values .name-value.heading > .name {
    padding-left: 35px;
}

.panel-body .name-values .name-value.heading > .value {
    padding-right: 35px;
    padding-top: 9px;
    padding-bottom: 0;
}

.panel-body .name-values .name-value.heading > .value .btn-xs {
    margin-top: 4px;
}

.panel-body .name-values .name-value.heading > .value > * {
}

.name-values .name-value.heading > .name h3,
.name-values .name-value.heading > .name h4 {
    margin: 8px 0 0 0;
    padding: 0;
    text-transform: none;
}

.name-values .name-value.heading > .name h4 {
    margin: 6px 0 2px 0;
}

.panel-body .name-values:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 992px) {

    .name-values .name-value.heading > .value {
        padding-left: 35px;
    }

    .name-values .name-value.heading > .value > .pull-right {
        float: none !important;
    }

    .name-values .name-value > .name {
        min-height: unset;
        padding-bottom: 0;
    }

}

/* Button */

.btn {
    font-size: 15px;
    font-weight: 400;
    padding: 13px 25px 12px 25px;
    text-shadow: none;
    text-transform: none;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.form-inline button,
.form-inline a.btn {
    margin-top: 0;
}

.btn .fa.right {
    margin: 0 0 0 10px !important;
}

.btn[disabled],
.btn[disabled]:focus,
.btn[disabled]:active,
.btn[disabled]:hover {
    background: var(--btn-backgroundColor--disabled) !important;
    border-color: var(--btn-backgroundColor--disabled) !important;
    color: var(--btn-color--disabled) !important;
    filter: alpha(opacity=20);
}

/* Button - Large */

.btn-lg {
    font-size: 15px;
    font-weight: 400;
    padding: 13px 30px;
    text-transform: none;
}

.btn-lg .fa {
    font-size: 14px;
    margin: 5px 0 0 12px;
    padding: 0 0 0 0;
}

.btn-lg .fa.right {
    margin: 0px 0 2px 8px;
    vertical-align: middle;
}

.btn-lg .fa:after {
    content: " ";
}

/* Button - Small */

.btn-sm {
    font-weight: 500;
    padding: 6px 20px 5px 20px;
}

/* Button - Extra Small */

.btn-xs {
    font-size: 12px;
    padding: 3px 12px 2px 12px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.btn.btn-xs .fa {
}

.dropdown .btn-xs {
    padding: 4px 12px 3px 12px;
}

/* Button - Status */

.dropdown .status.btn-xs {
    font-weight: 400;
}

.dropdown .status.btn-xs .caret::after {
    top: 3px;
    right: 10px;
}

/* Button - Default */

.btn-default,
.btn-default.active,
.btn-default.disabled,
.btn-default:active,
.btn-default[disabled],
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled]:hover,
.open > .dropdown-toggle.btn-default,
.open > .dropdown-toggle.btn-default:hover {
    background: var(--btn-default-backgroundColor);
    border: 1px solid var(--btn-default-backgroundColor) !important;
    color: var(--btn-default-color);
}

.btn-default:hover,
.btn-default:focus,
.btn-default.active:hover,
.btn-default:active:hover,
.btn-default:active:focus {
    background: var(--btn-default-backgroundColor-focus);
    border: 1px solid var(--btn-default-backgroundColor-focus) !important;
    color: var(--btn-default-color);
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.btn-default.disabled,
.btn-default[disabled] {
}

.status.btn-default[disabled],
.status.btn-default[disabled]:focus,
.status.btn-default[disabled]:active,
.status.btn-default[disabled]:hover {
    background-color: var(--btn-default-backgroundColor);
    border: 1px solid var(--btn-default-backgroundColor);
    color: var(--btn-default-color);
    opacity: 1.0;
}

.dropdown-toggle.btn-default .caret::after {
    color: var(--btn-default-color);
}

.panel-default .btn-default {
    background-color: var(--btn-default-backgroundColor-focus);
}

.btn.btn-default .fa {
    color: var(--btn-default-color);
}

.btn.btn-default:hover .fa {
    color: var(--btn-default-color);
}

/* Button - Primary */

.btn-primary,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary:active,
.btn-primary[disabled],
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:hover,
.dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary:hover {
    background-color: var(--btn-primary-backgroundColor);
    border: 1px solid var(--btn-primary-backgroundColor);
    color: var(--btn-primary-color);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active:hover,
.btn-primary:active:hover,
.btn-primary:active:focus {
    background-color: var(--btn-primary-backgroundColor-focus);
    border-color: var(--btn-primary-backgroundColor-focus);
    color: var(--btn-primary-color);
}

.status.btn-primary[disabled],
.status.btn-primary[disabled]:focus,
.status.btn-primary[disabled]:active,
.status.btn-primary[disabled]:hover {
    background-color: var(--btn-primary-backgroundColor);
    border: 1px solid var(--btn-primary-backgroundColor);
    color: var(--btn-primary-color);
    opacity: 1.0;
}

.dropdown-toggle.btn-primary .caret::after {
    color: var(--btn-primary-color);
}

.btn.btn-primary .fa {
    color: var(--btn-primary-color);
}

.btn.btn-primary:hover .fa {
    color: var(--btn-primary-color);
}

/* Button - Success */

.btn-success,
.btn-success.active,
.btn-success.disabled,
.btn-success:active,
.btn-success[disabled],
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled]:hover,
.open > .dropdown-toggle.btn-success,
.open > .dropdown-toggle.btn-success:hover {
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
}

.btn-success:hover,
.btn-success.active,
.btn-success:focus,
.btn-success.active:hover,
.btn-success:active:hover,
.btn-success:active:focus {
    background-color: #449d44;
    border-color: #449d44;
    color: #fff;
}

.status.btn-success[disabled],
.status.btn-success[disabled]:focus,
.status.btn-success[disabled]:active,
.status.btn-success[disabled]:hover {
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    opacity: 1.0;
}

.dropdown-toggle.btn-success .caret::after {
    color: #449d44;
}

/* Dropdown Menu */

.dropdown-menu {
    border-color: var(--panel-default-borderColor);
}

.dropdown-menu,
.dropdown-menu > li > a {
    background: var(--form-control-backgroundColor--focus);
    color: var(--form-control-color);
}

.dropdown-menu > li:hover > a:hover {
    background: var(--form-control-backgroundColor);
    color: var(--form-control-color);
    /*background: var(--btn-primary-backgroundColor);*/
    /*color: var(--btn-primary-color);*/
}

/* Form */

label.control-label,
.has-error .control-label {
    color: var(--form-control-label-color);
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
}

.form-group.required label {
    position: relative;
}

.form-group.required label:after {
    content: "*";
    color: var(--form-group-required--form-control-label-color);
    font-size: 24px;
    position: absolute;
    margin-left: 6px;
    top: -5px;
}

.lbl-heading {
    color: #A2AFCD;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 50px;
    text-transform: uppercase;
}

.help-block,
.has-warning .help-block {
    color: var(--form-control-help-block-color);
}

.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
    color: var(--form-control-label-color--has-warning);
}

.has-error .form-control,
.has-error .input-group > .input-group-addon {
    border-color: var(--form-control-borderColor--has-error);
}

.has-error .form-control:focus {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .help-block {
    color: #7F8FA4;
}

.help-block.error {
    color: #FF3E55 !important;
    display: block;
    background: transparent;
    font-size: 11px;
    font-weight: 400;
    padding: 0;
    margin-top: 5px;
    text-align: right !important;
    text-transform: none;
}

.form-group-videos-across-1 .help-block.error {
    text-align: left !important;
    margin-top: 15px;
}

.form-control.input-text-area {
    min-height: 200px;
}

.form-group-input-date-picker .react-datepicker-wrapper,
.form-group-input-date-picker .help-block {
    width: 290px;
}

/*.help-block.error*/

.form-control:not(.input-inline-editor):not(.input-text-area),
.form-control.big:not(.input-inline-editor):not(.input-text-area),
.form-control.lg:not(.input-inline-editor):not(.input-text-area) {
    font-size: 16px;
    height: auto;
    padding-top: 11px;
    padding-bottom: 11px;
}

.input-lg,
.form-group-lg .form-control {
    font-size: 20px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 12px;
}

.form-group-search::before {
    /*color: #7E87AC;*/
    color: #ccc;
    content: "\f002";
    font-family: "FontAwesome", serif;
    position: absolute;
    right: 30px;
    top: 12px;
}

.form-group-search > input {
    padding-right: 40px;
}

.input-select-enhanced-container > .input-select-enhanced > .__control {
    border-color: var(--form-control-backgroundColor);
    min-height: 48px;
}

.input-select-enhanced-container > .input-select-enhanced > div > .__value-container {
    padding: 2px 10px;
}

.input-select-enhanced-container > .input-select-enhanced .__dropdown-indicator {
    background: none;
}

.input-select-enhanced-container > .input-select-enhanced .__dropdown-indicator:after {
    content: '\f0d7';
    position: absolute;
    font-family: 'FontAwesome';
    top: 12px;
    right: 15px;
}

.form-control:not(.input-inline-editor),
.form-control.big:not(.input-inline-editor),
.form-control.lg:not(.input-inline-editor) {
    padding-top: 10px;
    padding-bottom: 10px;
}

.form-control,
div.input-rich-text > div,
div.input-rich-text .input-rich-text-toolbar,
div.input-rich-text .input-rich-text-toolbar button,
.input-select-enhanced-container > .input-select-enhanced > div,
.input-select-enhanced-container > .input-select-enhanced .__menu-list {
    color: var(--form-control-color);
    background-color: var(--form-control-backgroundColor);
    border-width: 2px;
    border-color: var(--form-control-borderColor);
    border-radius: 4px !important;
}

.has-warning .form-control,
.has-warning div.input-rich-text .input-rich-text-toolbar,
.has-warning .input-select-enhanced-container > .input-select-enhanced > div,
.has-warning .input-select-enhanced-container > .input-select-enhanced .__menu-list {
    color: var(--form-control-color--has-warning);
    background-color: var(--form-control-backgroundColor--has-warning);
    border-color: var(--form-control-borderColor--has-warning);
    box-shadow: none;
}

.disabled .form-control,
input[type="text"].form-control[disabled],
input[type="text"].form-control:disabled,
input[type="password"].form-control[disabled],
input[type="password"].form-control:disabled,
textarea.form-control:disabled,
div.input-rich-text-disabled,
div.input-rich-text-disabled > div,
.input-select-enhanced-container > .input-select-enhanced.__--is-disabled div,
.disabled .input-select-enhanced-container > .input-select-enhanced > div,
.disabled .input-select-enhanced-container > .input-select-enhanced .__menu-list,
.input-select-enhanced-container > .input-select-enhanced.--is-disabled > div > .__value-container > .__single-value {
    background: var(--form-control-backgroundColor--disabled);
    color: var(--form-control-color--disabled);
}

.form-control:focus,
.has-warning .form-control:focus,
.input-select-enhanced-container > .input-select-enhanced .__control--is-focused,
.has-warning .input-select-enhanced-container > .input-select-enhanced .__control--is-focused,
.input-select-enhanced-container > .input-select-enhanced .__control--is-focused + .__menu .__menu-list {
    border-width: 2px;
    border-color: var(--form-control-borderColor--focus);
    background-color: var(--form-control-backgroundColor--focus);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

div.form-group-input-date-picker .date-field {
    background-image: none;
}

div.form-group-input-date-picker .react-datepicker__input-container-inner:after {
    content: '\f0d7';
    position: absolute;
    font-family: 'FontAwesome';
    font-size: 16px;
    top: 13px;
    right: 15px;
}

.input-month-year-picker {
    background: var(--form-control-backgroundColor);
}

.xxinput-month-year-picker::after {
    content: '\f0d7';
    position: absolute;
    font-family: 'FontAwesome';
    top: 17px;
    right: 15px;
}

div.input-rich-text .input-rich-text-toolbar {
    margin: 4px 14px -3px 14px;
    padding-bottom: 7px;
}

div.input-rich-text .input-rich-text-toolbar > div > div > div > div > input[type="text"]:focus {
    border-color: #C8FF00;
}

div.input-rich-text .public-DraftEditor-content {
    padding: 12px;
}

.input-select-enhanced-container > .input-select-enhanced .__control--is-focused + .__menu .__menu-list {
    margin-top: 1px;
}

.input-select-enhanced-container > .input-select-enhanced .__control--menu-is-open {
}

.input-select-enhanced-container > .input-select-enhanced .__menu .__option {
    background: var(--form-control-backgroundColor--focus);
    border-color: var(--form-control-backgroundColor) !important;
    padding-top: 12px;
    padding-bottom: 12px;
}

.input-select-enhanced-container > .input-select-enhanced .__menu .__option--is-focused {
    background: var(--form-control-backgroundColor);
    color: var(--form-control-color);
}

.input-select-enhanced-container > .input-select-enhanced .__menu .__option--is-selected {
    color: var(--btn-primary-backgroundColor);
}

input[type="text"].form-control,
input[type="password"].form-control,
textarea.form-control,
.input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value,
.required .input-select-enhanced-container > .input-select-enhanced > div > .__value-container > .__single-value,
.input-select-enhanced-container > .input-select-enhanced > div > .__value-container .__input,
div.input-rich-text > div {
    color: var(--form-control-color);
}

.form-group .input-group-with-prefix > .input-group-addon,
.form-group.disabled .input-group-with-prefix > .input-group-addon {
    background: var(--form-control-backgroundColor);
    border-color: var(--form-control-borderColor);
    border-width: 2px;
    height: auto;
    margin-top: 10px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.form-group.disabled .input-group-with-prefix > .input-group-addon {
    background: var(--form-control-backgroundColor--disabled);
}

.form-group.has-error .input-group-with-prefix > .input-group-addon {
    border-color: var(--form-control-borderColor--has-error);
}

.form-group .input-group-with-suffix > .input-group-addon,
.form-group.disabled .input-group-with-suffix > .input-group-addon {
    background: var(--form-control-backgroundColor);
    border-color: var(--form-control-borderColor);
    border-width: 2px;
    height: auto;
    margin-top: 10px;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.form-group.disabled .input-group-with-suffix > .input-group-addon {
    background: var(--form-control-backgroundColor--disabled);
}

.form-group.has-error .input-group-with-suffix > .input-group-addon {
    border-color: var(--form-control-borderColor--has-error);
}

.form-group .input-group-with-prefix input {
    -moz-border-radius-topleft: 0 !important;
    -moz-border-radius-bottomleft: 0 !important;
    -webkit-border-top-left-radius: 0 !important;
    -webkit-border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.form-group .input-group-with-prefix input:not(:focus) {
    border-left-color: var(--form-control-backgroundColor) !important;
}

.form-group.disabled .input-group-with-prefix input:not(:focus) {
    border-left-color: var(--form-control-backgroundColor--disabled) !important;
}

.form-group .input-group-with-suffix input {
    -moz-border-radius-topright: 0 !important;
    -moz-border-radius-bottomright: 0 !important;
    -webkit-border-top-right-radius: 0 !important;
    -webkit-border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.form-group .input-group-with-suffix input:not(:focus) {
    border-right-color: var(--form-control-backgroundColor);
}

.form-group.disabled .input-group-with-suffix input:not(:focus) {
    border-right-color: var(--form-control-backgroundColor--disabled) !important;
}

.input-group > .input-group-addon .fa {
    color: var(--form-control-label-color);
}

.input-group-lg > .input-group-addon {
    padding-top: 7px !important;
}

.input-group-lg > .input-group-addon .fa {
    font-size: 17px;
}

.input-group-with-suffix .input-group-addon {
    padding-right: 14px;
}

.input-group-with-suffix .input-group-addon .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: hsl(0, 0%, 80%) !important;
    padding: 0;
}

.input-group-with-suffix .input-group-addon .dropdown .caret {
    margin-left: 10px;
}

.input-group-with-suffix .input-group-addon .dropdown .caret::after {
    color: hsl(0, 0%, 0%);
    font-size: 16px;
    top: -4px;
    right: 3px;
}

.input-group-with-suffix .input-group-addon .dropdown.open .dropdown-toggle,
.input-group-with-suffix .input-group-addon .dropdown.open .caret::after {
    color: hsl(0, 0%, 0%) !important;
}

.input-select-enhanced-container > .input-select-enhanced {
    font-family: "Inter", arial, helvetica, sans-serif;
}

.react-datepicker {
    font-family: "Inter", arial, helvetica, sans-serif;
    border-color: #f2f1ef;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #F6F9FF;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #f2f1ef;
}

.react-datepicker__header {
    background: #F6F9FF;
    border-color: #f2f1ef;
}

.react-datepicker__day:hover {
    background: #f2f1ef;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background: #f2f1ef;
}

div.input-rich-text {
    font-family: "Inter", arial, helvetica, sans-serif;
}

div.input-rich-text .input-rich-text-editor {
    font-family: "Inter", arial, helvetica, sans-serif;
}

div.input-rich-text .public-DraftEditor-content > div > blockquote {
    font-family: "Inter", arial, helvetica, sans-serif;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.radio input,
.checkbox input {
    margin-top: 4px;
}

.list.blocks > .block > .drag-handle {
    background: var(--list-blocks--drag-color);
}

.list.blocks.horizontal .block .prepend:before,
.list.blocks.horizontal .block .append:before {
    border-left-color: var(--list-blocks--drag-color);
}

.list.blocks.vertical .block .prepend:before,
.list.blocks.vertical .block .append:before {
    border-top-color: var(--list-blocks--drag-color);
}

.list.blocks.horizontal .block .prepend .fa,
.list.blocks.horizontal .block .append .fa,
.list.blocks.vertical .block .prepend .fa,
.list.blocks.vertical .block .append .fa {
    color: var(--list-blocks--drag-color);
}

.form-group-input-checkbox ~ .form-group-input-checkbox,
.form-group-input-radio ~ .form-group-input-radio {
    margin-top: -15px;
}

.checkbox label,
.radio label {
    letter-spacing: 0;
    margin-top: 3px;
    padding-left: 35px;
}

.checkbox,
.radio {
    position: relative;
}

.checkbox.padded,
.radio.padded {
    margin-bottom: 30px;
}

.checkbox label:before,
.radio label:before {
    background: var(--form-control-backgroundColor);
    border: 1px solid var(--form-control-borderColor);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.checkbox.disabled label:before,
.radio.disabled label:before {
    background: var(--form-control-backgroundColor);
}

.form-group.has-error .checkbox label:before,
.form-group.has-error .radio label:before {
    border: 1px solid #FF3E55;
}

.checkbox.checked label:after,
.radio.checked label:after {
    background: var(--btn-primary-backgroundColor);
    color: var(--btn-primary-color);
    content: "\f00c";
    font-family: "FontAwesome", sans-serif;
    font-size: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.form-group.disabled .checkbox.checked label:after,
.form-group.disabled .radio.checked label:after {
    /*background: #D3DEED;*/
    background: var(--form-control-backgroundColor--disabled);
    color: var(--form-control-color--disabled);
}

.radio label:before {
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
}

.radio.checked label:after {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

div.input-inline-editor {
    padding-bottom: 10px !important;
}

.form-group > div.input-inline-editor.read-only {
    padding-bottom: 0 !important;
}

div.input-inline-editor .codex-editor {
    margin: -15px -35px;
}

div.input-inline-editor.read-only {
    background: transparent;
    border: 0;
}

div.input-inline-editor.read-only .codex-editor {
    margin: -30px -50px;
}

div.input-inline-editor.read-only .codex-editor .codex-editor__redactor {
    padding: 0;
}

div.input-inline-editor.read-only .ce-block:last-child,
div.input-inline-editor.read-only .ce-block:last-child > *:last-child,
div.input-inline-editor.read-only .ce-block:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

div.input-inline-editor .ce-block--selected .ce-block__content {
    background: transparent;
}

div.input-inline-editor .ce-inline-toolbar .ce-inline-toolbar__dropdown,
div.input-inline-editor .ce-inline-toolbar .ce-inline-toolbar__buttons .ce-inline-tool,
div.input-inline-editor .ce-inline-tool-input,
div.input-inline-editor .ce-conversion-toolbar__tools {
    color: var(--main-content-backgroundColor);
}

/*@media (max-width: 650px) {*/
/*    div.input-inline-editor .ce-toolbar.ce-toolbar--opened {*/
/*        background: var(--main-content-backgroundColor);*/
/*        box-shadow: none;*/
/*        border: 1px solid #000;*/
/*    }*/
/*    div.input-inline-editor .ce-toolbar__actions-buttons {*/
/*        margin-top: 0;*/
/*        margin-right: -5px !important;*/
/*    }*/
/*}*/
/*@media (max-width: 650px) {*/
/*    div.input-inline-editor .ce-toolbar__actions-buttons {*/
/*        margin-top: 0 !important;*/
/*        margin-right: -5px !important;*/
/*    }*/
/*}*/

/*div.input-inline-editor .ce-toolbar__actions-buttons {*/
/*    margin-top: -18px;*/
/*    margin-right: -37px;*/
/*}*/

/*@media not all and (min-resolution: .001dpcm) {*/
/*    @media {*/
/*        div.input-inline-editor .ce-toolbar__actions-buttons {*/
/*            margin-top: -16px;*/
/*        }*/
/*    }*/
/*}*/

div.input-inline-editor .ce-toolbar__plus {
    color: var(--form-control-color);
    background: var(--form-control-backgroundColor);
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

div.input-inline-editor .ce-toolbar__actions-buttons .ce-toolbar__settings-btn {
    color: var(--form-control-color);
    background: var(--form-control-backgroundColor);
    width: 34px;
    height: 34px;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

div.input-inline-editor .form-group-binary-files-with-upload .file-container {
    border-color: var(--divider-color);
}

div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:hover,
div.input-inline-editor .form-group-binary-files-with-upload .file-container-add:hover {
    background: var(--panel-default-backgroundColor);
}

div.input-inline-editor .form-group-binary-files-with-upload .file-container .file:before {
    color: var(--text-muted-color);
}

div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:after {
    color: var(--text-muted-color);
}

div.input-inline-editor .form-group-binary-files-with-upload .file-container.openable:hover:after {
    color: var(--body-color);
}

/*div.input-inline-editor .ce-block--drop-target + .ce-block { margin-top: 12px !important; }*/

div.input-inline-editor .cdx-block.ce-paragraph {
    line-height: 1.42857143;
}

/* Form Section */

.form-section {
    background: var(--form-section--backgroundColor);
    margin-bottom: 15px;
    padding: 20px 30px 20px 30px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.form-section.bordered {
    border: 1px solid #F2F1EF;
}

.form-section.blank {
}

.panel-body .form-section > :first-child {
    margin-top: 5px;
}

.panel-body .form-section:last-child {
    margin-bottom: 0;
}

.panel-body .form-section > .form-group:last-child {
    margin-bottom: 15px;
}

.panel-body .form-section > .row:last-child {
    margin-bottom: -15px;
}

.form-section.clickable {
    background: var(--form-section--backgroundColor);
    padding-top: 14px;
    padding-bottom: 14px;
}

.form-section.clickable:hover {
    background: var(--form-section--backgroundColor-alt);
}

.form-section.blank {
    padding: 150px 0;
}

.form-section h5 {
    color: var(--form-section--h5-color);
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
    /*margin-bottom: 20px;*/
}

.form-section.clickable > h5:first-child {
    margin-top: 0;
    margin-bottom: 0;
}

.form-section.clickable h5,
.form-section.clickable .fa {
    color: #7F8FA4;
}

.form-section h5,
.form-section:hover h5,
.form-section:hover .fa {
    color: #fff;
}

.form-section .fa.pull-right {
    margin-top: 2px;
}

@media screen and (max-width: 1200px) {
    .form-section.clickable h5 .fa.pull-right {
        float: right !important;
    }
}

.form-section .fa-minus-circle,
.form-section .fa-plus-circle {
    font-size: 18px;
}

.form-section > .pull-right > .fa {
    margin-left: 10px;
}

.form-section .fa-times {
    /*font-size: 18px;*/
    color: #ccc;
    cursor: pointer;
}

.form-section .form-group-vertical-tight {
    margin-bottom: 5px;
}

.form-section > :last-child,
.form-section > ul:last-child > li:last-child,
.form-section > ol:last-child > li:last-child,
.form-section > .row:last-child > div[class*='col-'] > .form-group,
.form-section > div:last-child > div:last-child > .row:last-child > div[class*='col-'] > .form-group,
.form-section > div:last-child > .row:last-child > div[class*='col-'] > .form-group {
    margin-bottom: 0;
}

.form-section .actions {
    border-top: 1px solid #EBEDF1;
    margin: 20px 0 0 0;
    padding: 15px 0 0 0;
}

.binary-images-with-upload.fit-cover .image-container,
.binary-images-with-upload.fit-cover .image-container img {
    border-radius: 4px;
}

.binary-images-with-upload .add {
    background: var(--binary-images-with-upload--add-backgroundColor);
    border-radius: 4px;
}

.panel-default .binary-images-with-upload .add {
    background: var(--binary-images-with-upload--add-backgroundColor-alt);
}

.binary-videos-with-upload .video-container,
.binary-videos-with-upload .video-container video {
    border-radius: 4px;
}

.binary-videos-with-upload .add {
    background: var(--binary-videos-with-upload--add-backgroundColor);
    border-radius: 4px;
}

.panel-default .binary-images-with-upload .add {
    background: var(--binary-images-with-upload--add-backgroundColor-alt);
}

.form-section > .form-group-binary-files-with-upload {
    margin-bottom: 0 !important;
}

.form-section > .row:first-child {
    /*margin-top: -5px;*/
}

.form-section + h5 {
    margin-top: 30px;
}

/* Selectable */

.selectable {
    background: #fff;
    border: 1px solid #f2f1ef;
    cursor: pointer;
    margin-bottom: 5px;
    overflow: hidden;
    padding: 13px 50px 12px 15px;
    position: relative;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.selectable.padded {
    padding: 35px 35px;
}

.selectable:hover {
    /*background: #e9f1f8;*/
    border: 1px solid #C8FF00;
}

.selectable.selected {
    background: #f8fafd;
    border-color: #f2f1ef;
}

.selectable.selected:after {
    color: #C8FF00;
    content: "\f00c";
    font-family: "FontAwesome", serif;
    position: absolute;
    right: 15px;
    top: 10px;
}

.selectable.navigable:not(.selected):after {
    color: #7E87AC;
    content: "\f054";
    font-family: "FontAwesome", serif;
    position: absolute;
    right: 15px;
    top: 12px;
}

.selectable.expandable:not(.selected):after {
    color: #7E87AC;
    content: "\f078";
    font-family: "FontAwesome", serif;
    position: absolute;
    right: 20px;
    top: 12px;
}

.selectable.collapsible:not(.selected):after {
    color: #7E87AC;
    content: "\f077";
    font-family: "FontAwesome", serif;
    position: absolute;
    right: 20px;
    top: 12px;
}

.selectable > :last-child {
    margin-bottom: 0;
}

.selectable.selected > h5:first-child {
    color: #C8FF00;
}

.selectable > h5:first-child {
    color: unset;
}

.selectable > h5 > span.pull-right {
    font-weight: 300;
    margin: 0 -30px 0 0;
}

.selectable.selected > h5 > span.pull-right {
    margin: 0;
}

.selectable > h5 > span.pull-right.padded {
    margin-right: 0;
}

/* Check */

div.check > ul,
div.check > ul > li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

div.check > ul > li {
    margin: 12px 0 !important;
}

div.check > ul > li:last-child {
    margin-bottom: 0 !important;
}

div.check > ul > li:before {
    content: "\f058";
    font-family: "FontAwesome", sans-serif;
    margin-right: 15px !important;
}

div.panel div.check > ul > li:before {
    color: #7F8FA4 !important;
}

/* Instructions */

.instructions {
    color: var(--instructions-color);
    font-size: 16px;
    margin: 30px 0;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.instructions ul, .instructions ol {
}

.row + .instructions {
    margin-top: 0;
}

.form-section .instructions {
    background: transparent;
    padding: 0;
    margin: 0 0 20px 0;
}

/* Tabs */

.tabs .nav {
    margin: 40px 0;
}

.panel-body .tabs .nav {
    margin: 0 0 40px 0;
}

.tabs .nav li a {
    font-size: 11px;
}

.tabs .nav li:not(:first-child) a {
    margin-left: -1px;
}

.tabs .nav li a {
    color: var(--tab-color);
    background: var(--tab-backgroundColor);
    border: 1px solid var(--tab-borderColor);
    border-radius: 0;
}

.tabs .nav li.active a {
    color: var(--tab-color-active);
    background: var(--tab-backgroundColor-active);
    border: 1px solid var(--tab-borderColor-active);
}

.tabs .nav li:not(.active) a:hover {
    color: var(--tab-color-hover);
    background: var(--tab-backgroundColor-hover);
    border-color: var(--tab-borderColor-hover);
}

.tabs .nav li:first-child a {
    border-radius: 4px 0 0 4px;
}

.tabs .nav li:last-child a {
    border-radius: 0 4px 4px 0;
}

.tabs .nav li a .index {
    margin-right: 5px;
}

.tabs .tabs-title-meta {
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .tabs .tabs-title-meta {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {

    .tabs .tabs-title-meta {
        text-align: center;
    }

    .tabs .nav li {
        margin-left: 0;
        margin-right: 0;
    }

    .tabs .nav li a {
        /*border-radius: 4px !important;*/
        border-radius: 0;
        margin: 0 0 -1px 0 !important;
    }

    .tabs .nav li:first-child a {
        border-radius: 4px 4px 0 0;
    }

    .tabs .nav li:last-child a {
        border-radius: 0 0 4px 4px;
    }

}

/* Print */

@media print {

    @page {
        margin: 1cm;
        size: A4;
    }

    @media print {
        html, body {
            margin: 0;
            width: 210mm;
            /*height: 297mm;*/
        }
    }

    .page-break-inside-avoid {
        page-break-inside: avoid;
    }

}
