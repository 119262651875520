.section-header {
    position: relative;
    margin-top: 40px;
}

.page-header + .section-header {
    margin-top: 0px;
}

.divider + .section-header {
    margin-top: -15px;
}

.panel-body > .section-header:first-child,
.panel-body > .section-header:first-child h2 {
    margin-top: 0;
}

.section-header p:last-child {
    margin-bottom: 30px;
}

.panel-body > .section-header p:last-child {
    background: var(--main-content-backgroundColor);
    border-radius: 4px;
    padding: 20px 30px;
}

.section-header h2,
.section-header h3 {
    margin-bottom: 30px;
}

.section-header h2 + p,
.section-header h3 + p {
    margin-top: -10px;
}

.section-header h2 .actions,
.section-header h3 .actions {
    position: absolute;
    right: 0;
    top: -64px;
}

.section-header h2 .actions > *,
.section-header h2 .actions > * > *,
.section-header h3 .actions > *,
.section-header h3 .actions > * > * {

    margin-left: 10px;
    margin-right: 0;
}

.section-header h3 .actions .btn-xs {
    font-size: 12px;
    padding: 3px 12px 2px;
}

@media screen and (max-width: 768px) {

    .section-header h2,
    .section-header h3 {
        overflow: hidden;
    }

    .section-header h2 .actions,
    .section-header h2 .actions > .pull-right,
    .section-header h3 .actions,
    .section-header h3 .actions > .pull-right {
        margin-bottom: 15px;
        position: unset;
        width: 100%;
    }

    .section-header h2 .actions > *,
    .section-header h3 .actions > * {
        margin: 15px 0 0 0;
    }

    .section-header h2 .actions > span.pull-right > *,
    .section-header h3 .actions > span.pull-right > * {
        display: block;
        margin: 15px 0 0 0;
    }

}
