/* Theme */

.theme-layout.placeholder {
    box-shadow: 0 0 15px #EBEDF1;
}

/* Container */

body, html {
    overflow-x: hidden;
}
@media screen and (max-width: 1200px) {
    #primary-nav #app-title {
        display: unset;
    }
}

/* Primary Nav */

#primary-nav .navbar-collapse.collapsing,
#primary-nav .navbar-collapse.in {
    background: var(--primary-nav-backgroundColor);
    border-top-color: var(--primary-nav-backgroundColor);
    margin-top: 10px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 1200px) {
    #primary-nav .navbar-nav {
        margin: 7.5px -15px;
    }
    #primary-nav .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
    }
}

/* Common */

.not-specified {
    color: #ccc;
}

/* Form */

.form-inline {
    margin-bottom: 20px;
}

/* Input Inline Editor */

div.input-inline-editor .codex-editor .ce-toolbar .ce-toolbox {
    background: transparent;
}

div.input-inline-editor .ce-toolbar__plus {
    border-radius: 4px;
}

.ce-popover {
    background-color: var(--main-content-backgroundColor);
    border-color: var(--divider-color);
}

.cdx-search-field {
    background-color: var(--main-content-backgroundColor);
}

.ce-popover-item {
    color: #fff;
}

.ce-popover-item:hover {
    background-color: var(--panel-default-backgroundColor);
}
.ce-popover-item__icon {
    background-color: var(--main-content-backgroundColor);
}

div.input-inline-editor .ce-toolbar__actions .ce-toolbar__settings-btn {
    background-color: var(--form-control-backgroundColor);
    border-radius: 4px;
    color: var(--body-color);
}

div.input-inline-editor .ce-toolbar__actions .ce-toolbar__settings-btn:hover {
    background-color: var(--form-control-backgroundColor);
}

/* Badge */

.badge {
    border-radius: 4px;
    font-weight: 400;
    font-size: 11px;
    padding: 3px 7px;
}

.badge.badge-primary {
    background-color: #000;
}

table tr:hover .badge.badge-primary {
    background-color: #fff;
    color: #000;
}
