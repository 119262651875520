#user-import-run-page {
}

#user-import-run-page .table-responsive {
    overflow: scroll;
    width: 100vw;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
}

#user-import-run-page .table-responsive th {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}

#user-import-run-page .table-responsive th.col-delete {
    min-width: 50px;
    max-width: 50px;
    width: 50px;
}

#user-import-run-page .table-responsive td.col-delete .fa {
    cursor: pointer;
    margin-top: 17px;
}

#user-import-run-page .table-responsive th.col-input-text-area {
    min-width: 600px;
    max-width: 600px;
    width: 600px;
}

#user-import-run-page .table-responsive th.col-input-inline-editor {
    min-width: 600px;
    max-width: 600px;
    width: 600px;
}

#user-import-run-page .table-responsive th.col-name {
    min-width: 350px;
    max-width: 350px;
    width: 350px;
}

#user-import-run-page .table-responsive td {
    background: var(--panel-table--th-backgroundColor);
    vertical-align: top;
}

#user-import-run-page .table-responsive th.col-sire,
#user-import-run-page .table-responsive th.col-dam,
#user-import-run-page .table-responsive th.col-place-of-birth,
#user-import-run-page .table-responsive th.col-farm {
    min-width: 350px;
    max-width: 350px;
    width: 350px;
}

#user-import-run-page .table-responsive th.col-foaled {
    min-width: 191px;
    max-width: 191px;
    width: 191px;
}

#user-import-run-page .table-responsive td.col-foaled .form-group-input-date-picker .react-datepicker-wrapper,
#user-import-run-page .table-responsive td.col-foaled .form-group-input-date-picker .help-block {
    width: 175px;
}

#user-import-run-page .table-responsive th.col-age {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
}
