.security-dialog-panel {
    margin-top: 150px;
}

.security-dialog-panel .dialog-panel {
    max-width: unset;
    min-height: 400px;
    overflow: hidden;
    text-align: left;
}

.security-dialog-panel:before {
    content: '';
    background-image: url('https://images.unsplash.com/photo-1569701046063-5dc54829489f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80');
    background-size: 1000px;
    position: absolute;
    height: 100%;
    width: 44%;
    left: 15px;
    top: 0;
    background-position: 60% -530px;
    border-radius: 4px 0 0 4px;
}

.security-dialog-panel .dialog-panel {
    margin-bottom: 0;
}

@media screen and (max-width: 992px) {

    .security-dialog-panel {
        margin-top: 0;
    }

    .security-dialog-panel:before {
        display: none;
    }

    .security-dialog-panel .col-padding {
        display: none;
    }

}