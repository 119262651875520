/* Image */

.image.blank {
    background: var(--image-blank-backgroundColor);
    display: inline-block;
    vertical-align: middle;
}

/* Avatar */

.avatar {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.avatar.blank {
    background: var(--image-blank-backgroundColor);
    display: inline-block;
    vertical-align: middle;
}

table td .avatar {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
}

table td .avatar:not(:last-child) {
    margin-right: 15px;
}
