.specification-page {
    background: #fff;
    color: #000;
    padding: 50px 0 100px;
}

.specification-page h1,
.specification-page h2,
.specification-page h3,
.specification-page h4,
.specification-page h4,
.specification-page h4 {
    color: #000;
}

.specification-page h2 {
    margin-top: 50px;
}

.specification-page .container div.mockup.wireframe.fragment .requirement-note > div {
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);*/
}

.specification-page div.mockup.wireframe.fragment > .row > div > .content {
    /*background: var(--panel-default-backgroundColor);*/
    background: #fafafa;
    /*color: #fff;*/
}

.specification-page div.mockup.wireframe.fragment > .row > div > .content .panel-body {
    padding: 8px;
}

.specification-page div.mockup.wireframe.fragment > .row > div > .content .panel-body:first-child {
    margin-top: 0;
    padding-top: 0;
}

.specification-page div.mockup.wireframe.fragment > .row > div > .content .panel-body:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.specification-page div.mockup.wireframe.fragment .content h1,
.specification-page div.mockup.wireframe.fragment .content h2,
.specification-page div.mockup.wireframe.fragment .content h3,
.specification-page div.mockup.wireframe.fragment .content h4,
.specification-page div.mockup.wireframe.fragment .content h5,
.specification-page div.mockup.wireframe.fragment .content h6 {
    /*color: #fff;*/
}

.specification-page div.mockup.wireframe.fragment .content input,
.specification-page div.mockup.wireframe.fragment .content select,
.specification-page div.mockup.wireframe .input-select-enhanced-container > .input-select-enhanced > .__control {
    /*background: var(--form-control-backgroundColor);*/
}

/* Secondary Nav */

.specification-page div.mockup.wireframe.fragment .content #secondary-nav {
    background: transparent;
    border: none;
    margin-left: -28px;
    margin-top: -40px;
}

.specification-page div.mockup.wireframe.fragment .content #secondary-nav li:first-child {
    margin-left: 20px;
}

.specification-page div.mockup.wireframe.fragment .content #secondary-nav li:last-child {
    margin-right: 20px;
}

/*Table*/

.specification-page div.mockup.wireframe.fragment .content .table,
.specification-page div.mockup.wireframe.fragment .content .table th,
.specification-page div.mockup.wireframe.fragment .content .table tr,
.specification-page div.mockup.wireframe.fragment .content .table td {
    background: transparent;
    color: #000;
}