.page-header {
    border: 0;
    margin: 0;
    padding: 0;
}

.page-header .binary-images-with-upload.avatar {
    background: #000;
    margin-top: 5px;
    margin-left: 0;
    padding-left: 0;
}

.page-header .binary-images-with-upload .add,
.page-header .binary-images-with-upload.avatar .image-container {
    margin-left: 0;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
}

.page-header .binary-images-with-upload.avatar .image-container .actions {
    top: 5px;
    right: 5px;
}

@media screen and (max-width: 992px) {

    .page-header .col-avatar img {
        margin-bottom: 40px;
    }

}

@media screen and (max-width: 767px) {

    .page-header .binary-images-with-upload.avatar {
        margin: 0 auto;
    }

    .page-header .col-avatar {
        text-align: center;
    }

}
