.banner-panel {
    border-radius: 24px;
    border: 2px solid var(--image-blank-backgroundColor);
    background: var(--image-blank-backgroundColor);
    color: var(--body-color);
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    overflow: hidden;
}

.banner-panel.active {
    border-color: var(--a-color);
}

.banner-panel .image.cover {
    height: 230px;
}

.banner-panel .meta {
    position: absolute;
    top: 190px;
    right: 38px;
}

.banner-panel .meta .btn {
    background: var(--btn-primary-backgroundColor) !important;
    color: var(--btn-primary-color) !important;
    opacity: 1.0;
}