.dashboard-layout {
    min-height: 100%;
    position: absolute;
    width: 100%;
}

/*

If dashboard view kicks in at 1400px, then
- the #secondary-nav width: 200px
- the #main-content margin-left: 200px

However, if we want a wider #secondary-nav (eg 100px more) without changing when the dashboard view kicks in
- the #secondary-nav width: 200 + 100 = 300px
- the #main-content margin-left: 200 + 100 = 300px
- the #main-content > .container width: 1170 - 100 = 1070px
- the #main-content > .container width: 1170 (when media min-width: 1401 + 100 = 1501px)

*/
@media screen and (min-width: 1401px) and (min-height: 600px) {

    .dashboard-layout #secondary-nav {
        background: var(--dashboard-layout--secondary-nav-backgroundColor);
        border-right: 1px solid #E6EAEE;
        top: 51px;
        width: 270px;
        height: 100%;
    }

    .dashboard-layout #secondary-nav h4 {
        color: var(--dashboard-layout--secondary-nav-h4-color);
    }

    .dashboard-layout #secondary-nav ul {
        margin-top: 20px;
    }

    .dashboard-layout #secondary-nav li {
        display: block;
        float: none;
    }

    .dashboard-layout #secondary-nav li a {
        border-bottom: 0;
        margin: 0;
        padding: 9px 0;
    }

    #secondary-nav .nav li a .fa {
        display: unset;
    }

    #secondary-nav .nav li.dashboard-title {
        display: unset;
    }

    #secondary-nav .nav li.dashboard-title:first-child h4 {
        margin-top: 10px;
    }

    .dashboard-layout #secondary-nav .nav > li > a.active,
    .dashboard-layout #secondary-nav .nav > li > a.active:hover {
        background: var(--dashboard-layout--secondary-nav-active-backgroundColor);
        border-left: 2px solid var(--secondary-nav-borderColor);
        border-bottom: 0;
        padding: 9px 0 9px 13px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .dashboard-layout #main-content {
        margin-top: 0;
        margin-left: 270px;
        padding-bottom: 550px;
        min-height: 100%;
        position: relative;
        z-index: 0;
    }

    .dashboard-layout #main-content:after {
        background: var(--main-content-backgroundColor);
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .dashboard-layout #main-content > .container {
        width: 1100px;
    }

    .dashboard-layout #footer-container {
        bottom: 0;
        position: absolute;
        width: 100%;
        z-index: 200;
    }

}

@media screen and (min-width: 1471px) {

    .dashboard-layout #main-content > .container {
        width: 1170px;
    }

}
